<template>
  <div :style="getElementStyle">
      <!-- Below code is for Before Field View -->

    <div v-if="!isDefalutPosq">
      <div style="display: flex; align-items: center;">
        <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
        <label for="text" style="white-space: nowrap; margin-right: 5px;">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold; margin-right: 5px;"
        >
          *
        </span>
        <span v-if="data.description" style="margin-right: 5px;">
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </span>
          <el-input
          :style="getStyle"
          :placeholder="data.placeholder"
          v-model="selectedValue"
        ></el-input>
      </div>
      <div>
        <span
        v-if="isActive || isDataTableField"
        class="setting-icon"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </span>
      </div>
    </div>
    <!-- <span style="display: none">{{ isDefalutPosq }}</span> -->
          <!-- Below code is for Top Field View -->

    <el-row align="middle" v-else>
      <el-col :span="showLabel && !isDefalutPosq ? 4 : data.description ? 20 : 24" ref="colRef" :style="computedStyles">
        <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
        <label for="text">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
         <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>

      <el-col :span="showLabel && isDefalutPosq ? 4 : 0">
        <!-- <span v-if="data.description">
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </span> -->
      </el-col>
      <el-row
        v-if="isActive || isDataTableField"
        class="setting-icon"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </el-row>
      <el-col :span="showLabel
        &&
        !isDefalutPosq
        ?
        12
        :
        24">
        <input
          :placeholder="data.placeholder"
          v-model="selectedValue"
          :style="{'text-align': inputTextAlignment(), ...getStyle}"
          class="slv"
        >
      </el-col>
    </el-row>

    <!--<div>
      <div>
        
        {{data.label}}
        <span v-if="data.description">
          <i class="el-icon-info" :title="data.description" />
        </span>
        <span v-if="isActive || isDataTableField" class="setting-icon" @click="openSettings">
          <i class="el-icon-s-tools" />
        </span>
      </div>
      <el-input :style="getStyle" :placeholder="data.placeholder" v-model="selectedValue"></el-input>-->
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import { fetchGlobalVariableById } from "@/repo/globalVariables";
export default {
  name: "AutoIncrementView",
  components: {},
  props: ["data", "value", "isActive", "isDataTableField","isIconRequired","labelAlignments"],

  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),

    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = 'flex';

      // Set justify-content based on data.styles.labelAlignments
      if(this.labelAlignments || this.data?.styles?.labelAlignments){
        styles.justifyContent = this.labelAlignments || this.data?.styles?.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
    getStyle() {
      let inputStyle = "";
      if (this.data.styles) {
        inputStyle += this.data.styles.input_background
          ? `background-color: ${this.data.styles.input_background}`
          : "";
      }
      inputStyle += ";";

      // inputStyle = inputStyle + `width: ${this.data.width}px !important;`;
      return inputStyle;
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }

      // return isDefalutPosq;
    },
    getElementStyle() {
      let borderStyle = "";

      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }

      return borderStyle;
    }
  },
  data() {
    return {
      validations: [],
      options: [],
      isList: false,
      // isDefalutPos: true,
      showLabel: true,
      inputTextAlignmentValue: 'left',
      // validations: [],
      // isDefalutPos: true,
      // showLabel: true
    };
  },
  mounted() {
    console.log("isDefalutPosq",this.isDefalutPosq);
    if (this.data.styles) {
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        // if (this.data.styles.labelStyle == "right") {
        //   this.isDefalutPos = false;
        // }
      }
    }

    this.options = this.data.options || [];
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    inputTextAlignment() {
    return this.data.styles && this.data.styles.inputTextAlignments
      ? this.data.styles.inputTextAlignments
      : 'left'; 
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      // await this.$store.dispatch(
      //   "globalVariables/fetchGlobalVariableById",
      //   params
      // );
      let globalVariable = await fetchGlobalVariableById(params);
      if (globalVariable) {
        if (globalVariable.input_type == "SINGLE_LINE_TEXT") {
          // this.isList = true;
          this.data.selectedValue = globalVariable.value;
          // this.data.is_list = true;
        } else {
          // this.data.selectedValue = this.getGlobalVariableById.value;
          // this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    }
  },
  watch: {
    isActive: {
      immediate: true,
      handler() {
      }
    },
    'data.styles.inputTextAlignments': {
    handler(newVal) {
      this.inputTextAlignmentValue = newVal;
    },
    immediate: true
    }
  }
};
</script>
  
<style lang="scss" scoped>
.el-input__inner {
  height: inherit !important;
}
</style>